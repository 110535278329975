define('m13/components/coordinat-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Coordinate = Ember['default'].Component.extend({
    tagName: 'coordinate',
    attributeBindings: ['point'],
    point: (function () {

      var name = this.get('name');
      var bauteil = this.get('model.x3d.bauteile');
      var values;
      bauteil.forEach(function (item) {
        if (item.name === name) {
          if (item.typ === "Pfeil") {
            values = bauteil.objectAt(item.id).punkt.p1 + " " + bauteil.objectAt(item.id).punkt.p2 + " " + bauteil.objectAt(item.id).punkt.p3 + " " + bauteil.objectAt(item.id).punkt.p4;
          }
        }
      });
      return values;
    }).property('model.x3d.bauteile')

  });

  exports['default'] = Coordinate;

});