define('m13/components/fontstyl-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Fontstyle = Ember['default'].Component.extend({
    tagName: 'fontstyle'
  });

  exports['default'] = Fontstyle;

});