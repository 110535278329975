define('m13/components/tex-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var x3dText = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    austrittspunktRechts: false,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;
      var skalierungsfaktor = x3d.get('skalierungsfaktor');
      var item = x3d.get('bauteile').findBy('id', name);

      var zVerschiebung = Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z')) / 20;

      if (x3d.get('schalungsdickeAktiv') || x3d.get('schalungsdickeEingetragen')) {
        zVerschiebung = Number(bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).get('z')) / 20;
      }

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var korrektur = 0.05;
      var abstandVonDerMasslinie = 0.05;

      switch (typ) {
        case 'breite':
          z = 0;

          if (name === 'material1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette - Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = -0.15 - Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);
            } else {
              x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette - Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);
            }
          } else if (name === 'material2') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -abstandVonDerMasslinie - 2 * abstandVomBauteil - 2 * hoeheMasskette - Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = -0.15 - bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
            } else {
              x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = abstandVonDerMasslinie - 2 * abstandVomBauteil - 2 * hoeheMasskette - Number(bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).get('y') / 10) - Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10) - Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);
            }
          }

          break;
        case 'hoehe':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
            y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);

            // if (name === 'schalung1'){
            //   z = -abstandVonDerMasslinie -abstandVomBauteil -hoeheMasskette -zVerschiebung;
            // }else{
            z = -abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette - zVerschiebung;
            // }
          } else {
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = -Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 20);
              // if (name === 'schalung1'){
              //   x = -abstandVonDerMasslinie -abstandVomBauteil -hoeheMasskette -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x')/20);
              // }else{
              x = -abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette - Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              // }
            }
          break;
        case 'laenge':

          var deltaY = 0;

          if (x3d.get('ergebnisGeladen')) {
            deltaY = abstandVonDerMasslinie + 2 * abstandVomBauteil + hoeheMasskette;
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 10);
            z = -abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette - deltaY - zVerschiebung;
          } else {
            x = 0;
            y = abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette + deltaY + Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 10);
            z = -Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 20);
          }
          break;

      }

      if (item.get('typ') === 'standardSchraube') {

        var hoeheLastenpfeil = bauteile.findBy('id', 'gleichlast1').get('boxsizes').objectAt(0).get('y') + 0.2;
        var y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y') / 10;

        switch (typ) {
          case 'randAbstand1':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10) + Number(x3d.get('Randabstand')) / 20;
              y = y;
              z = -zVerschiebung - abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette;
            } else {
              x = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10) + Number(x3d.get('Randabstand')) / 20;
              y = y + 2 * abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette;
              z = 0;
            }
            break;
          case 'schraubenAbstand1':
            var a1 = Number(x3d.get('a1'));
            if (a1 > 30) {
              a1 = 30;
            }

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10) + Number(x3d.get('Randabstand')) / 10 + a1 / 20;
              y = y;
              z = -zVerschiebung - abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette;
            } else {
              x = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10) + Number(x3d.get('Randabstand')) / 10 + a1 / 20;
              y = y + 2 * abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette;
              z = 0;
            }
            break;
          case 'AbstaendeZusammengefasst1':
            var a1 = Number(x3d.get('a1'));
            if (a1 > 30) {
              a1 = 30;
            }

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -0.1 - (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x')) / 10 - Number(x3d.get('Randabstand')) / 10 - a1 / 10) / 2;
              y = y;
              z = -zVerschiebung - abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette;
            } else {
              x = -0.1 - (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x')) / 10 - Number(x3d.get('Randabstand')) / 10 - a1 / 10) / 2;
              y = y + 2 * abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette;
              z = 0;
            }
            break;
          case 'AbstandMitte':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0;
              y = y;
              z = -zVerschiebung - abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette;

              if (x3d.get('AbstaendeZusammengefasst').substring(0, 1) !== '0') {
                z = z - 0.3;
              }
            } else {
              x = 0;

              y = y + 2 * abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette;

              if (x3d.get('AbstaendeZusammengefasst').substring(0, 1) !== '0') {
                y = y + 0.3;
              }

              z = 0;
            }
            break;
          case 'AbstaendeZusammengefasst2':
            var a1 = Number(x3d.get('a1'));
            if (a1 > 30) {
              a1 = 30;
            }

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0.1 + (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x')) / 10 - Number(x3d.get('Randabstand')) / 10 - a1 / 10) / 2;
              y = y;
              z = -zVerschiebung - abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette;
            } else {
              x = 0.1 + (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x')) / 10 - Number(x3d.get('Randabstand')) / 10 - a1 / 10) / 2;
              y = y + 2 * abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette;
              z = 0;
            }
            break;
          case 'schraubenAbstand2':
            var a1 = Number(x3d.get('a1'));
            if (a1 > 30) {
              a1 = 30;
            }

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - Number(x3d.get('Randabstand')) / 10 - a1 / 20;
              y = y;
              z = -zVerschiebung - abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette;
            } else {
              x = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - Number(x3d.get('Randabstand')) / 10 - a1 / 20;
              y = y + 2 * abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette;
              z = 0;
            }
            break;
          case 'randAbstand2':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - Number(x3d.get('Randabstand')) / 20;
              y = y;
              z = -zVerschiebung - abstandVonDerMasslinie - abstandVomBauteil - hoeheMasskette;
            } else {
              x = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - Number(x3d.get('Randabstand')) / 20;
              y = y + 2 * abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette;
              z = 0;
            }
            break;
          case 'randAbstand3':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0.1 + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 + abstandVonDerMasslinie + abstandVomBauteil + hoeheMasskette;
              y = y;
              z = Number(x3d.get('zPosMin')) / 10 - (Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z')) / 20 - Math.abs(Number(x3d.get('zPosMin')) / 10)) / 2;
            } else {
              x = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10;
              y = y + abstandVonDerMasslinie + 2 * abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette;
              z = Number(x3d.get('zPosMin')) / 10 - (Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z')) / 20 - Math.abs(Number(x3d.get('zPosMin')) / 10)) / 2;
            }
            break;
          case 'schraubenAbstand3':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0.1 + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 + 0.3 + abstandVonDerMasslinie + abstandVomBauteil + hoeheMasskette;
              z = 0;

              if (x3d.get('randAbstand4') !== '0') {
                x = x + 0.3;
              }
            } else {
              x = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10;
              y = y + abstandVonDerMasslinie + 2 * abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette;
              z = 0;

              if (x3d.get('randAbstand4') !== '0') {
                y = y + 0.3;
              }
            }
            break;
          case 'randAbstand4':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0.1 + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 + abstandVonDerMasslinie + abstandVomBauteil + hoeheMasskette;
              y = y;
              z = Number(x3d.get('zPosMax')) / 10 + (Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 20) - Number(x3d.get('zPosMax') / 10)) / 2;
            } else {
              x = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10;
              y = y + abstandVonDerMasslinie + 2 * abstandVonDerMasslinie + abstandVomBauteil + 2 * hoeheMasskette;
              z = Number(x3d.get('zPosMax')) / 10 + (Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 20) - Number(x3d.get('zPosMax') / 10)) / 2;
            }
            break;
        }
      }

      if (name.substring(0, 11) === 'lastenPfeil') {
        y = -1.25 - this.positionDurchSkalierung(0);
      }

      cords = x + ' ' + y + ' ' + z;
      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.viewpointHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');
      var values;
      var x = 0,
          y = 0,
          z = 0,
          omega = 0;
      var w90Grad = 90 * Math.PI / 180;
      var w240Grad = 240 * Math.PI / 180;
      var item = x3d.get('bauteile').findBy('id', name);

      switch (typ) {
        case 'breite':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = -1;
            z = -1;
            omega = w240Grad;
          } else {
            x = 0;
            y = 1;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'hoehe':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 1;
            z = -1;
            omega = w240Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = w90Grad;
          }
          break;
        case 'laenge':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = 0;
          }
          break;
      }

      if (typ === 'randAbstand1' || typ === 'schraubenAbstand1' || typ === 'AbstaendeZusammengefasst1' || typ === 'AbstandMitte' || typ === 'AbstaendeZusammengefasst2' || typ === 'schraubenAbstand2' || typ === 'randAbstand2') {
        if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
          x = 1;
          y = 0;
          z = 0;
          omega = -w90Grad;
        } else {
          x = 0;
          y = 0;
          z = 1;
          omega = 0;
        }
      }

      if (typ === 'randAbstand3' || typ === 'schraubenAbstand3' | typ === 'randAbstand4') {
        if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
          x = 1;
          y = 1;
          z = 1;
          omega = w240Grad;
        } else {
          x = 0;
          y = 1;
          z = 0;
          omega = -w90Grad;
        }
      }

      values = x + ' ' + y + ' ' + z + ' ' + omega;

      if (name.substring(0, 11) === 'lastenPfeil') {
        values = '0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.viewpointHelper'),

    wert: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = ' ';

      var item = x3d.get('bauteile').findBy('id', name);

      switch (typ) {
        case 'breite':
          if (name === 'material1' && x3d.get('htBreiteEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          }
          if (name === 'material2' && x3d.get('ntBreiteEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          }
          break;
        case 'hoehe':
          if (name === 'material1' && x3d.get('htHoeheEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          if (name === 'material2' && x3d.get('ntHoeheEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          if (name === 'schalung1' && x3d.get('schalungsdickeEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          break;
        case 'laenge':
          if (name === 'material1' && x3d.get('systemlaengeEingetragen')) {
            value = Number(x3d.get('systemlaenge')).toFixed(1);
          }
          break;
      }

      if (item.get('typ') === 'standardSchraube') {

        switch (this.get('typ')) {
          case 'randAbstand1':
            value = Number(x3d.get('Randabstand'));
            value = parseFloat(value).toFixed(1);
            break;
          case 'schraubenAbstand1':
            value = Number(x3d.get('a1'));
            value = parseFloat(value).toFixed(1);
            break;
          case 'AbstaendeZusammengefasst1':
            value = x3d.get('AbstaendeZusammengefasst');
            break;
          case 'AbstandMitte':
            value = Number(x3d.get('AbstandMitte'));
            value = parseFloat(value).toFixed(1);
            break;
          case 'AbstaendeZusammengefasst2':
            value = x3d.get('AbstaendeZusammengefasst');
            break;
          case 'schraubenAbstand2':
            value = Number(x3d.get('a1'));
            value = parseFloat(value).toFixed(1);
            break;
          case 'randAbstand2':
            value = Number(x3d.get('Randabstand'));
            value = parseFloat(value).toFixed(1);
            break;
          case 'randAbstand3':
            value = Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z')) / 2 - Math.abs(Number(x3d.get('zPosMin')));
            value = parseFloat(value).toFixed(1);
            break;
          case 'schraubenAbstand3':
            var wert = (Math.abs(Number(x3d.get('zPosMax'))) + Math.abs(Number(x3d.get('zPosMin')))) / (Number(x3d.get('n90')) - 1);
            wert = parseFloat(wert).toFixed(1);
            value = Number(x3d.get('n90')) - 1 + 'x' + wert;
            break;
          case 'randAbstand4':
            value = Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z')) / 2 - Math.abs(Number(x3d.get('zPosMax')));
            value = parseFloat(value).toFixed(1);
            break;
        }
      }

      if (name === 'lastenPfeil1') {
        value = x3d.get('querkraft');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      return value;
    }).property('model.firstObject.transformHelper'),

    diffusecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('htbreiteAktiv') === true && name === 'material1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name === 'material1' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('ntbreiteAktiv') === true && name === 'material2' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('nthoeheAktiv') === true && name === 'material2' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('schalungsdickeAktiv') === true && name === 'schalung1' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('systemlaengeAktiv') === true && name === 'material1' && typ === 'laenge') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.ntbreiteAktiv', 'model.firstObject.nthoeheAktiv', 'model.firstObject.schalungsdickeAktiv', 'model.firstObject.systemlaengeAktiv'),

    textgroesse: (function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    }).property('model.firstObject.skalierungsfaktor', 'model.firstObject.transformHelper'),

    getSchraubeKopfPos: function getSchraubeKopfPos(name, achse) {
      var value = 0;

      var x3d = this.get('model').objectAt(0);

      var item = x3d.get('bauteile').findBy('id', name);

      var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      var alpha = item.get('rotations').objectAt(0).get('z');
      var beta = item.get('rotations').objectAt(0).get('y');

      if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
        alpha = 1.5707 - (alpha - 1.5707);
        xVerschiebung = xVerschiebung * -1;
      }

      var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

      // var xKopfPos = -(Math.cos(beta)*yVerschiebung/Math.tan(alpha) - xVerschiebung);

      var xKopfPos;

      if (xVerschiebung > 0) {

        var vwinkel = x3d.get('vWinkel');
        var xGesamt = yVerschiebung / Math.tan(alpha - 1.5707);
        var x2 = Math.abs(xGesamt) - xVerschiebung / Math.cos(beta);
        var gamma = Math.PI - (alpha - 1.5707) - vwinkel * Math.PI / 180;
        var a = x2 * Math.sin(alpha - 1.5707) / Math.sin(gamma);
        xKopfPos = Math.cos(vwinkel * Math.PI / 180) * a * -1;
        xKopfPos = Math.cos(beta) * xKopfPos;
      } else {
        xKopfPos = -(Math.cos(beta) * yVerschiebung / Math.tan(alpha) - xVerschiebung);
        if (Number(xKopfPos) > Number(x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10)) {
          this.austrittspunktRechts = true;
          xKopfPos = (xKopfPos - x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10) * Math.sin(alpha);
        }
      }

      var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

      if (achse === 'x') {
        value = xKopfPos;
      } else if (achse === 'z') {
        value = zKopfPos;
      }

      return value;
    },

    positionDurchSkalierung: function positionDurchSkalierung(richtung) {
      var x3d = this.get('model').objectAt(0);
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;
      // var value = ((0.6*skalierungsfaktor) + richtung*(0.14/skalierungsfaktor));
      var value = 0.2 * skalierungsfaktor;
      return 0;
    }

  });

  exports['default'] = x3dText;

});