define('m13/components/schraub-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schraube = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraub-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      var name = this.get('name');

      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';

      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = bauteile.findBy('id', name);

      var x = item.get('translations').objectAt(0).get('x');
      var y = item.get('translations').objectAt(0).get('y');
      var z = item.get('translations').objectAt(0).get('z');

      if (x3d.get('ergebnisGeladen') === false) {
        var winkel = Math.abs(Number(item.get('rotations').objectAt(0).get('z')));

        var materialLaenge = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x');

        if (winkel === 0 || winkel === 3.1416) {
          //0° und 180°
          x = x * (materialLaenge / 5);
        } else if (winkel === 3.927) {
          // 225°
          x = (materialLaenge - Math.abs(x)) * Math.abs(x) / x;
        } else if (winkel === 0.7854) {
          //45°
          x = 0 + x;
        }
      } else {
        y = y + Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y'));
      }

      values = x / 10 + ' ' + y / 10 + ' ' + z / 10;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    // Rotation in der XY-Ebene, um die z-Achse
    rotation: (function () {
      var name = this.get('name');
      var drehwinckelZAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('z');
      var drehwinckelYAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('y');
      var values = '';

      values = '0 0 1 ' + drehwinckelZAchse;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt')
  });

  exports['default'] = Schraube;

});