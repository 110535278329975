define('m13/instance-initializers/ember-i18n', ['exports', 'ember', 'ember-i18n/legacy-helper', 'm13/config/environment'], function (exports, Ember, legacyHelper, ENV) {

  'use strict';

  exports['default'] = {
    name: 'ember-i18n',

    initialize: function initialize(instance) {
      var defaultLocale = (ENV['default'].i18n || {}).defaultLocale;
      if (defaultLocale === undefined) {
        Ember['default'].warn('ember-i18n did not find a default locale; falling back to "en".');
        defaultLocale = 'en';
      }
      var key = 'service:i18n';
      var i18n = instance.lookup ? instance.lookup(key) : instance.container.lookup(key);
      i18n.set('locale', defaultLocale);

      if (legacyHelper['default'] != null) {
        Ember['default'].HTMLBars._registerHelper('t', legacyHelper['default']);
      }
    }
  };

});