define('m13/models/geometrie', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var geometrie = DS['default'].Model.extend({

		MatKeyBestandstraeger: DS['default'].attr('string'),
		FKLKeyBestandstraeger: DS['default'].attr('string'),
		TimberElement1_b: DS['default'].attr('string'),
		TimberElement1_h: DS['default'].attr('string'),
		MatKeyVerstaerkungstraeger: DS['default'].attr('string'),
		FKLKeyVerstaerkungstraeger: DS['default'].attr('string'),
		TimberElement2_b: DS['default'].attr('string'),
		TimberElement2_h: DS['default'].attr('string'),
		MatKeySchalung: DS['default'].attr('string'),
		FKLKeySchalung: DS['default'].attr('string'),
		schalungsdicke: DS['default'].attr('string'),
		systemlaenge: DS['default'].attr('string'),

		TimberElement1_vorgebohrt: DS['default'].attr('boolean'),
		TimberElement2_vorgebohrt: DS['default'].attr('boolean')

	});

	geometrie.reopenClass({
		FIXTURES: [{
			id: 1,
			MatKeyBestandstraeger: "0",
			FKLKeyBestandstraeger: "5",
			TimberElement1_b: "20",
			TimberElement1_h: "20",
			MatKeyVerstaerkungstraeger: "0",
			FKLKeyVerstaerkungstraeger: "5",
			TimberElement2_b: "20",
			TimberElement2_h: "20",
			MatKeySchalung: "0",
			FKLKeySchalung: "5",

			TimberElement1_vorgebohrt: false,
			TimberElement2_vorgebohrt: false,

			schalungsdicke: "0.0",
			systemlaenge: "0.5"
		}]
	});

	exports['default'] = geometrie;

	// bauteile.reopenClass({
	// 	FIXTURES: [
	// 		{
	// 			id: 1,
	// 			MatKeyTimberElementHT: "0",
	// 			FKLKeyTimberElementHT: "5",
	// 			TimberElementHT_b: "20",
	// 			TimberElementHT_h: "20",
	// 			MatKeyTimberElementNT: "0",
	// 			FKLKeyTimberElementNT: "5",
	// 			TimberElementNT_b: "20",
	// 			TimberElementNT_h: "20",
	// 		}
	// 	]
	// });

});