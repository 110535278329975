define('m13/controllers/lasteinwirkung', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: false,
    openFromFile: false,
    displayErrors: true,
    loading: false,
    bmlast: "",
    verbindungsmittelliste: "",
    led: 1,
    vorbelastungDisabled: true,
    vorbelastung: 0.0,
    staendigeEinwirkungen: "0.00",
    vEinwirkung1: "0.00",
    vEinwirkung2: "0.00",

    vEinwirkung1Aktiv: false,
    vEinwirkung2Aktiv: false,

    kledBez1: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('eigenlastStaendig');
    }),
    kledBez2: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('eigenlastStaendig');
    }),

    kled1: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('staendig');
    }),
    kled2: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('staendig');
    }),

    stahl: false,

    ttStaendigeEinwirkungen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.01 - 999.0 [kN/m]";
    }),
    ttVorbelastung: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "";
    }),
    ttVEinwirkung1: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.0 - 999.0 [kN/m]";
    }),
    ttVEinwirkung2: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.0 - 999.0 [kN/m]";
    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      nkl: {
        type: "",
        tooltip: "ttNutzungsklasse",
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 3
        }
      },
      staendigeEinwirkungen: {
        numericality: {
          greaterThanOrEqualTo: 0.01,
          lessThanOrEqualTo: 999.0
        }
      },
      vorbelastung: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      vEinwirkung1: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      vEinwirkung2: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      }
    },

    ledarray: [{
      id: 1,
      name: "staendig"
    }, {
      id: 2,
      name: "lang"
    }, {
      id: 3,
      name: "mittel"
    }, {
      id: 4,
      name: "kurz"
    }, {
      id: 5,
      name: "sehr kurz"
    }],

    nklassen: [],
    nkl: 1,

    aeuEinwirkungen: [],
    aeussereEinwirkungen: 1,

    init: function init() {
      var self = this;
      this._super();

      var applicationdata = this.controllerFor('application').get('model').application.objectAt(0);

      var lang = applicationdata.get('countrycode');
      var einschraubtiefen = "einschraubtiefen";

      self.setSelectFieldsContent();

      // this.set('nklassen', nutzungsklassenDB.nutzungsklassen_de);
      this.set('nkl', 1);

      // this.set('aeuEinwirkungen', aeussereEinwirkungenDB.aeussereEinwirkungen_de);
      this.set('aeussereEinwirkungen', 1);
    },

    setValues: function setValues(values) {
      var self = this;

      if (self.debug) {
        console.log("values in lasteinwirkung: ");
        console.log(values);
      }

      this.set('openFromFile', true);

      this.set('staendigeEinwirkungen', Number(values.staendigeEinwirkungen).toFixed(1));

      this.set('vEinwirkung1', Number(values.verEinw1).toFixed(1));
      this.set('vEinwirkung2', Number(values.verEinw2).toFixed(1));
      this.set('vorbelastung', Number(values.vorbelastung).toFixed(1));

      this.set('led', parseInt(values.KLED_ID));
      this.set('ledText', values.LED_Text);
      this.set('nkl', parseInt(values.NKL_ID));
      this.set('nklText', values.nklText);

      this.set('eigengewicht', parseInt(values.eigengewicht));
      this.set('kled1', parseInt(values.kled1));
      this.set('kled2', parseInt(values.kled2));

      // 1/2, mittel, textid, 302
      this.send('setKLED', 1, kledMenuDB[parseInt(values.lastIDs[1])].kled, kledMenuDB[parseInt(values.lastIDs[1])].textid, parseInt(values.lastIDs[1]));
      this.send('setKLED', 2, kledMenuDB[parseInt(values.lastIDs[2])].kled, kledMenuDB[parseInt(values.lastIDs[2])].textid, parseInt(values.lastIDs[2]));

      // this.setx3d(staendigeEinwirkungenrounded, {target:{name:"staendigeEinwirkungen"}});
      // this.setx3d(vEinwirkung1rounded, {target:{name:"vEinwirkung1"}});
      // this.setx3d(vEinwirkung2rounded, {target:{name:"vEinwirkung2"}});

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setNklassen();
      self.setAeuEinwirkungen();
    },

    setNklassen: function setNklassen() {
      var self = this;
      var indices = [1, 2, 3];

      var maxNKLM1 = materialien.liste.findBy('IndexNr', self.controllerFor('geometrie').get('htfkl')).MaxNKL;
      var maxNKLM2 = materialien.liste.findBy('IndexNr', self.controllerFor('geometrie').get('ntfkl')).MaxNKL;

      var maxValues = [maxNKLM1, maxNKLM2];

      maxValues = maxValues.sort(function (a, b) {
        return a - b;
      });

      self.set('validations.nkl.numericality.lessThanOrEqualTo', maxValues[0]);

      self.set('nklassen', self.getSelectFieldContent('nklassen', indices, maxValues[0]));
    },

    setAeuEinwirkungen: function setAeuEinwirkungen() {
      var self = this;
      var indices = [1, 2, 3];
      self.set('aeuEinwirkungen', self.getSelectFieldContent('aeuEinwirkungen', indices, 9999));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes, maxValue) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            var disabled = dbContent[k].disabled;
            if (db === 'nklassen' && dbContent[k].id > maxValue) {
              disabled = true;
            }
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), disabled: disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'nklassen':
          dbContent = nutzungsklassenDB.nutzungsklassen_de;
          break;
        case 'aeuEinwirkungen':
          dbContent = aeussereEinwirkungenDB.aeussereEinwirkungen_de;
          break;
      }

      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {
      if (!this.openFromFile) {
        if (this.get('staendigeEinwirkungen').indexOf(",") !== -1) {
          this.set('staendigeEinwirkungen', this.get('staendigeEinwirkungen').replace(",", "."));
        }
        if (this.get('vEinwirkung1').indexOf(",") !== -1) {
          this.set('vEinwirkung1', this.get('vEinwirkung1').replace(",", "."));
        }
        if (this.get('vEinwirkung2').indexOf(",") !== -1) {
          this.set('vEinwirkung2', this.get('vEinwirkung2').replace(",", "."));
        }
      }
    }).observes('staendigeEinwirkungen', 'vEinwirkung1', 'vEinwirkung2'),

    watchComboboxes: (function () {

      var self = this;

      self.controllerFor('supercontroller').resetApplication();

      var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

      lasteinwirkung.set('NKL_ID', this.get('nkl'));

      var nklText = this.nklassen[this.get('nkl') - 1].name.toString();
      // var ledText = this.ledarray[this.get('led') - 1].name;

      lasteinwirkung.set('NKL_Text', nklText);
      lasteinwirkung.set('My_KLED_ID', this.get('led'));

      var ledText = this.ledarray.findBy('id', this.get('led')).name.toString();
      lasteinwirkung.set('LED_Text', ledText);

      lasteinwirkung.set('bemessungsvariante', self.get('aeussereEinwirkungen'));
    }).observes('aeussereEinwirkungen', 'nkl'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3ddefault = application.get('model').x3ddefault.objectAt(0);

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'bmlast':
          if (this.errors.get('bmlast').length === 0) {
            x3ddefault.set('bemessungslast', value);
          }
          break;
      }

      application.get('model').x3ddefault.objectAt(0).set('transformHelper', !this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('transformHelper'));
    },

    actions: {

      setKLED: function setKLED(lastnr, kled, textid, last) {

        var self = this;

        console.log("setKLED controller" + lastnr + " / " + kled + "/" + textid);

        self.set('kledBez' + lastnr, self.get('i18n').t(textid));
        self.set('kled' + lastnr, self.get('i18n').t(kled));

        var lasteinwirkung = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

        lasteinwirkung.set('kled' + lastnr, last);
      },

      proceedInput: function proceedInput(event) {

        var self = this;

        var ledText = this.ledarray.findBy('id', this.get('led')).name.toString();
        var nklText = self.nklassen[self.get('nkl')].name.toString();

        var validation = self.get('isValid');

        if (validation === true) {

          var lasteinwirkung = self.store.createRecord('lasteinwirkung', {

            KLED_ID: self.get('led'),
            LED_Text: ledText,
            NKL_ID: self.get('nkl'),
            NKL_Text: nklText,
            V_Ed: self.get('bmlast').replace(",", ".")

          });
        }

        self.validate().then(function () {
          // all validations pass
          this.transitionToRoute('verbindungsmittel');
        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {

            self.controllerFor('supercontroller').resetApplication();

            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            var applicationdata = application.get('model').application.objectAt(0);
            var applicationdataJSON = JSON.stringify(applicationdata);

            var projektdatendata = application.get('model').projektdaten.objectAt(0);
            var projektdatendataJSON = JSON.stringify(projektdatendata);
            //
            // var geometriedata = application.get('model').geometrie.objectAt(0);
            // var geometriedataJSON = JSON.stringify(geometriedata);
            //
            // var systemdata = application.get('model').system.objectAt(0);
            // var systemdataJSON = JSON.stringify(systemdata);
            //
            // // var schraubenausrichtungdata = application.get('model').schraubenausrichtung.objectAt(0);
            // // var schraubenausrichtungdataJSON = JSON.stringify(schraubenausrichtungdata);
            //
            // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
            // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
            //
            // if (self.debug) {
            //   console.log("projektdatendata: " + projektdatendataJSON);
            //   console.log("applicationdataJSON: " + applicationdataJSON);
            //   console.log("geometriedataJSON: " + geometriedataJSON);
            //   console.log("systemdataJSON: " + systemdataJSON);
            //   console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
            // }
            //
            // var uebergabedaten = $.extend(true, JSON.parse(geometriedataJSON), JSON.parse(systemdataJSON), JSON.parse(lasteinwirkungdataJSON));
            //
            // var bestandstraeger = {
            //   "Querschnittswerte": {
            //     "b": geometriedata.get('TimberElement1_b'),
            //     "h": geometriedata.get('TimberElement1_h'),
            //     "t_Fastener": geometriedata.get('TimberElement1_h'),
            //     "QuerschnittsID": "1"
            //   },
            //   "vorgebohrt": false
            // };
            //
            // var verstaerkungstraeger = {
            //   "Querschnittswerte": {
            //     "b": geometriedata.get('TimberElement2_b'),
            //     "h": geometriedata.get('TimberElement2_h'),
            //     "t_Fastener": geometriedata.get('TimberElement2_h'),
            //     "QuerschnittsID": "1"
            //   },
            //   "vorgebohrt": false
            // };
            //
            // var schalung = {
            //   "Querschnittswerte": {
            //     "b": "0.0",
            //     "h": geometriedata.get('schalungsdicke'),
            //     "t_Fastener": geometriedata.get('schalungsdicke'),
            //     "QuerschnittsID": "1"
            //   },
            //   "vorgebohrt": false
            // };
            //
            // var externeLasten = [
            //   lasteinwirkungdata.get('staendigeEinwirkungen'), // ständige einwirkungen
            //   lasteinwirkungdata.get('verEinw1'), // ver. einw'). 1
            //   lasteinwirkungdata.get('verEinw2')  // ver. einw. 2
            // ];
            //
            // var standardloads = [
            //   lasteinwirkungdata.get('eigengewicht'), // vermutlich auswahl "einfeldträger"
            //   lasteinwirkungdata.get('kled1'), // kled menu 1
            //   lasteinwirkungdata.get('kled2')  // kled menu 2
            // ];
            //
            // uebergabedaten.bestandstraeger = bestandstraeger;
            // uebergabedaten.verstaerkungstraeger = verstaerkungstraeger;
            // uebergabedaten.schalung = schalung;
            // uebergabedaten.externeLasten = externeLasten;
            // uebergabedaten.lastIDs = standardloads;
            //

            // var JSONdata = JSON.stringify(uebergabedaten);
            var JSONdata = JSON.stringify(self.controllerFor('supercontroller').uebergabedatenErzeugen());

            var zusatz = self.controllerFor('supercontroller').erzeugeAufrufzusatz(false);

            if (self.debug) {
              console.log("**********************************");
              console.log(' ');
              console.log("uebergabedaten: ");
              console.log(JSONdata);
              console.log(' ');
              console.log("**********************************");
            }

            var vmittel = self.controllerFor('verbindungsmittel');
            vmittel.set('loading', true);

            self.set('loading', true);
            self.transitionToRoute('verbindungsmittel');

            var initialized = applicationdata.get('initialized');

            var server = application.get('server');
            var pfad = application.get('pfad');

            console.log('server: ' + server);
            console.log('pfad: ' + pfad);

            if (!initialized) {

              $.ajax({
                type: "POST",
                url: server + "initialisiereBerechnung/",
                //url: "http://136.243.4.143/berechnungsservice/x3d/DE/erzeugeSchraubenliste/",
                data: {
                  kennung: applicationdataJSON,
                  // projektdaten: projektdatendataJSON,
                  paras: JSONdata,
                  modul: "m13",
                  pfad: pfad
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

                console.log('cid: ' + data);

                var cid = data;

                applicationdata.set('Calculation_ID', cid);
                applicationdata.set('initialized', true);

                $.ajax({
                  type: "POST",
                  url: server + "erzeugeSchraubenliste/",
                  data: {
                    cid: cid
                  }
                }).done(function (data, statusText, xhr) {

                  self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                  console.log(data);

                  // var liste = JSON.parse(data);
                  // self.verbindungsmittelliste = liste;
                  // var core = treetools.buildTree(liste, true);
                  // vmittel.set('schraubenliste', core)

                  if (Number(JSON.parse(data).Item1) === 0) {
                    var liste = JSON.parse(JSON.parse(data).Item2);
                    self.verbindungsmittelliste = liste;
                    var core = treetools.buildTree(liste, true);
                    vmittel.set('schraubenliste', core);
                    vmittel.set('keineSchraubenliste', false);
                  } else if (Number(JSON.parse(data).Item1) === 1) {
                    application.set('verbindungsmittelInvalid', false);
                    application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                    vmittel.set('infoText', JSON.parse(data).Item3);
                    vmittel.set('keineSchraubenliste', true);
                  }

                  vmittel.set('loading', false);
                  vmittel.set('calcid', cid);
                  applicationdata.set('treeLoaded', true);

                  self.set('loading', false);
                });
              });
            } else {

              var cid = applicationdata.get('Calculation_ID');

              $.ajax({
                type: "POST",
                url: server + "erzeugeSchraubenliste/",
                data: {
                  cid: cid
                }
              }).done(function (data, statusText, xhr) {

                self.controllerFor('supercontroller').logaufruf("erzeugeSchraubenliste", xhr.status);

                console.log('DATA ' + data);

                if (Number(JSON.parse(data).Item1) === 0) {
                  var liste = JSON.parse(JSON.parse(data).Item2);
                  self.verbindungsmittelliste = liste;
                  var core = treetools.buildTree(liste, true);
                  vmittel.set('schraubenliste', core);
                  vmittel.set('keineSchraubenliste', false);
                } else if (Number(JSON.parse(data).Item1) === 1) {
                  application.set('verbindungsmittelInvalid', false);
                  application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                  vmittel.set('infoText', JSON.parse(data).Item3);
                  vmittel.set('keineSchraubenliste', true);
                }

                vmittel.set('loading', false);
                vmittel.set('calcid', cid);
                applicationdata.set('treeLoaded', true);

                self.set('loading', false);
              });
            }
          } else {
            self.set('displayErrors', true);
            application.set('lasteinwirkungInvalid', true);
          }
        });
      },

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          // this.setX3D(value, event);

        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('lasteinwirkungInvalid', false);

            self.controllerFor('supercontroller').resetApplication();

            var lasteinwirkung = self.controllerFor('application').get('model').lasteinwirkung.objectAt(0);

            lasteinwirkung.set('V_Ed', parseFloat(self.get('scherkraft')).toFixed(1));
            lasteinwirkung.set('ALFA_Force_RAD', parseFloat(self.get('winkelScherkraft')).toFixed(1));
            lasteinwirkung.set('N_Ed', parseFloat(self.get('axialkraft')).toFixed(1));
            // self.set('bmlast', parseFloat(self.get('bmlast').replace(",", ".")).toFixed(1));

            lasteinwirkung.set('staendigeEinwirkungen', parseFloat(self.get('staendigeEinwirkungen')).toFixed(2));
            lasteinwirkung.set('verEinw1', parseFloat(self.get('vEinwirkung1')).toFixed(2));
            lasteinwirkung.set('verEinw2', parseFloat(self.get('vEinwirkung2')).toFixed(2));
            lasteinwirkung.set('vorbelastung', parseFloat(self.get('vorbelastung')).toFixed(2));
          } else {
            self.set('displayErrors', true);
            application.set('lasteinwirkungInvalid', true);
          }
        });
      },

      staendigeEinwirkungenIsSelected: function staendigeEinwirkungenIsSelected() {
        var bmlastaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bemessungslastAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('bemessungslastAktiv', !bmlastaktiv);

        if (this.get('staendigeEinwirkungen') !== "") {
          var staendigeEinwirkungenwork = parseFloat(this.get('staendigeEinwirkungen').replace(",", "."));
          var staendigeEinwirkungenrounded = staendigeEinwirkungenwork.toFixed(2);
          this.set('staendigeEinwirkungen', staendigeEinwirkungenrounded);
          this.send('validation', staendigeEinwirkungenrounded, { target: { name: "staendigeEinwirkungen" } });
        }

        if (bmlastaktiv === false) {
          document.getElementsByName('staendigeEinwirkungen')[0].setSelectionRange(0, this.get('staendigeEinwirkungen').length);
        }
      },

      vEinwirkung1IsSelected: function vEinwirkung1IsSelected() {
        var vEinwirkung1Aktiv = this.get('vEinwirkung1Aktiv');
        this.set('vEinwirkung1Aktiv', !vEinwirkung1Aktiv);

        if (this.get('vEinwirkung1') !== "") {
          var vEinwirkung1work = parseFloat(this.get('vEinwirkung1').replace(",", "."));
          var vEinwirkung1rounded = vEinwirkung1work.toFixed(2);
          this.set('vEinwirkung1', vEinwirkung1rounded);
          this.send('validation', vEinwirkung1rounded, { target: { name: "vEinwirkung1" } });
        }

        if (vEinwirkung1Aktiv === false) {
          document.getElementsByName('vEinwirkung1')[0].setSelectionRange(0, this.get('vEinwirkung1').length);
        }
      },

      vEinwirkung2IsSelected: function vEinwirkung2IsSelected() {
        var vEinwirkung2Aktiv = this.get('vEinwirkung2Aktiv');
        this.set('vEinwirkung2Aktiv', !vEinwirkung2Aktiv);

        if (this.get('vEinwirkung2') !== "") {
          var vEinwirkung2work = parseFloat(this.get('vEinwirkung2').replace(",", "."));
          var vEinwirkung2rounded = vEinwirkung2work.toFixed(2);
          this.set('vEinwirkung2', vEinwirkung2rounded);
          this.send('validation', vEinwirkung2rounded, { target: { name: "vEinwirkung2" } });
        }

        if (vEinwirkung2Aktiv === false) {
          document.getElementsByName('vEinwirkung2')[0].setSelectionRange(0, this.get('vEinwirkung2').length);
        }
      }
    }
  });

});