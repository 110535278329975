define('m13/components/schraube-n', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schrauben = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraube-n',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var values = '0 0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    schraubenListe: (function () {
      var self = this;
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var count = 0;
      var schrListe = [];

      bauteile.forEach(function (item) {
        if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
          count = count + 1;
          schrListe.push(item);

          if (x3d.get('ergebnisGeladen') === true) {
            var rVektor = item.get('richtungsvektor').objectAt(0);

            // var yWinkel = self.getWinkel({x: rVektor.get('x'), y: 0, z: rVektor.get('z')}, {x: 0, y: 0, z:1});
            var zWinkel = self.getWinkel({ x: rVektor.get('x'), y: rVektor.get('y'), z: rVektor.get('z') }, { x: 0, y: 1, z: 0 });

            // console.log('----- Winkel zwischen 0 -1 0 und 0 1 0: '+self.getWinkel({x: 0, y: -1, z: 0}, {x: 0, y: 1, z:0})+' -----');
            // console.log('----- Winkel zwischen 0.707 0.707 0 und 0 1 0: '+self.getWinkel({x: 0.707, y: 0.707, z: 0}, {x: 0, y: 1, z:0})+' -----');
            // console.log('----- Winkel zwischen -0.707 0.707 0 und 0 1 0: '+self.getWinkel({x: -0.707, y: 0.707, z: 0}, {x: 0, y: 1, z:0})+' -----');

            // x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('y', yWinkel);
            x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).set('z', zWinkel);
          }
        }
      });

      if (this.get('model').objectAt(0).get('ergebnisGeladen')) {
        this.setAbstaende(schrListe);
      }

      // console.log('+++++ Schraubenliste neu berechnet +++++');

      return schrListe.toArray();
    }).property('model.firstObject.schraubenlisteAktualisiert'),

    getWinkel: function getWinkel(vektor, bezugsVektor) {
      var self = this;

      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;

      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);

      var richtung = 1;
      if (Number(vektor.y) !== 0) {
        richtung = vektor.y;
      }

      var winkel = richtung / Math.abs(richtung) * Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));

      return winkel;
    },

    vektorLaenge: function vektorLaenge(v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    },

    setAbstaende: function setAbstaende(schrliste) {
      var x3d = this.get('model').objectAt(0);

      var xPosListe = [];
      var zPosListe = [];

      var xPosMax = 0,
          xPosMin = 0,
          zPosMax = 0,
          zPosMin = 0;

      var schraubenLaenge = 0;
      var einschraubWinkel = 0;

      var werteGesetzt = false;

      schrliste.forEach(function (item) {
        var xVerschiebung = item.get('punkt').objectAt(0).get('x');

        var zVerschiebung = item.get('punkt').objectAt(0).get('z');
        xPosListe.push(xVerschiebung);
        zPosListe.push(zVerschiebung);
        if (werteGesetzt === false) {
          x3d.set('schraubenLaenge', Number(item.get('screwProperties').objectAt(0).get('length')));
          x3d.set('einschraubWinkel', Number(item.get('rotations').objectAt(0).get('z')));
          werteGesetzt = true;
        }
      });

      xPosListe.sort(function (a, b) {
        return b - a;
      });
      zPosListe.sort(function (a, b) {
        return b - a;
      });

      xPosMax = xPosListe[0];
      xPosMin = xPosListe[xPosListe.length - 1];

      x3d.set('xPosMax', Math.abs(xPosMax));
      x3d.set('xPosMin', Math.abs(xPosMin));

      zPosMax = zPosListe[0];
      zPosMin = zPosListe[zPosListe.length - 1];

      x3d.set('zPosMax', zPosMax);
      x3d.set('zPosMin', zPosMin);

      var a1 = Number(x3d.get('a1'));

      // console.log('----- a1 alt: '+a1+' -----');

      if (a1 > 30) {
        a1 = 30;
      }

      // console.log('Schraubenlänge: ' + Number(x3d.get('schraubenLaenge')));
      // console.log('Einschraubwinkel: '+ Number(x3d.get('einschraubWinkel')));
      // console.log('Multiplikator: ' + Math.abs(Math.sin(Number(x3d.get('einschraubWinkel')))));

      var neueTraegerlaenge = 0;

      // if(Number(x3d.get('einschraubWinkel')) < 0){
      //   neueTraegerlaenge = Number(x3d.get('xPosMin')) + a1 + Number(x3d.get('Randabstand')) + Number(x3d.get('schraubenLaenge')) * Math.abs(Math.sin(Number(x3d.get('einschraubWinkel'))));
      // }else{
      neueTraegerlaenge = Number(x3d.get('xPosMin')) + a1 + Number(x3d.get('Randabstand'));
      // }

      x3d.get('bauteile').findBy('id', 'material1').get('boxsizes').objectAt(0).set('x', neueTraegerlaenge);
      x3d.get('bauteile').findBy('id', 'material2').get('boxsizes').objectAt(0).set('x', neueTraegerlaenge);
      x3d.get('bauteile').findBy('id', 'schalung1').get('boxsizes').objectAt(0).set('x', neueTraegerlaenge);

      // console.log('----- x3d xPos min: '+x3d.get('xPosMin')+' -----');
      // console.log('----- a1: '+a1+' -----');
      // console.log('----- Randabstand: '+x3d.get('Randabstand')+' -----');
      // console.log('----- Neue Trägerlänge: '+neueTraegerlaenge+' -----');

      schrliste.forEach(function (item) {
        var xVerschiebung = item.get('punkt').objectAt(0).get('x');

        if (a1 === 30 && Number(xVerschiebung) !== Number(xPosMin)) {
          // console.log('----- a1 === 30 && Number(xVerschiebung) ' +xVerschiebung+ ' !== Number(xPosMin) '+xPosMin+' -----');
          xVerschiebung = Number(xPosMin) + 30;
          x3d.set('xPosMax', Math.abs(0.1 + xVerschiebung));
        }

        // console.log('----- xVerschiebung: '+xVerschiebung+' -----');
        // console.log('----- x neu: '+(-neueTraegerlaenge-0.1+xVerschiebung)+' -----');

        item.get('translations').objectAt(0).set('x', -neueTraegerlaenge - 0.1 + xVerschiebung);
      });

      x3d.set('transformHelper', !x3d.get('transformHelper'));
    },

    ergebnisGeladen: (function () {

      return this.get('model').objectAt(0).get('ergebnisGeladen');
    }).property('model.firstObject.ergebnisGeladen'),

    abstaendeAnzeigenUndErgebnisGeladen: (function () {
      if (this.get('model').objectAt(0).get('abstaendeAnzeigen') && this.get('model').objectAt(0).get('ergebnisGeladen')) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.abstaendeAnzeigen', 'model.firstObject.ergebnisGeladen'),

    mehrereSchraubenAufZAchse: (function () {

      var value = false;

      if (Number(this.get('model').objectAt(0).get('n90')) > 1) {
        value = true;
      }

      return value;
    }).property('model.firstObject.ergebnisGeladen'),

    ansichtLinks: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtFront: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtOben: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint'),

    abstaendeZusammengefasstAnzeigen: (function () {
      var value = true;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('AbstaendeZusammengefasst').substring(0, 1) === '0') {
        value = false;
      }

      return value;
    }).property('model.firstObject.transformHelper')

  });

  exports['default'] = Schrauben;

});