define('m13/controllers/geometrie', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,
    ttHoeheHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
    }),
    ttBreiteHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
    }),
    ttHoeheNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
    }),
    ttBreiteNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "2.0 - 9999.9 [cm]";
    }),
    ttWinkel: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.0 - 90.0 [cm]";
    }),
    ttSchalungsdicke: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.0 - 6.0 [cm]";
    }),
    ttSystemlaenge: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.5 - 999.9 [m]";
    }),
    i18n: Ember['default'].inject.service(),
    self: undefined,
    hauptnebentraeger: '',
    htbreite: "",
    hthoehe: "",
    ntbreite: "",
    nthoehe: "",
    schalungsdicke: "",
    systemlaenge: "",

    toolTip: "test tooltip",
    htfkl: 5,
    ntfkl: 5,
    htholz: 0,
    ntholz: 0,
    httraeger: true,
    winkel: '',
    stahl: false,

    validations: {
      htbreite: {
        numericality: {
          greaterThanOrEqualTo: 2,
          lessThanOrEqualTo: 9999.9
        }
      },
      hthoehe: {
        numericality: {
          greaterThanOrEqualTo: 2,
          lessThanOrEqualTo: 9999.9
        }
      },
      ntbreite: {
        numericality: {
          greaterThanOrEqualTo: 2,
          lessThanOrEqualTo: 9999.9
        }
      },
      nthoehe: {
        numericality: {
          greaterThanOrEqualTo: 2,
          lessThanOrEqualTo: 9999.9
        }
      },
      schalungsdicke: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 6
        }
      },
      systemlaenge: {
        numericality: {
          greaterThanOrEqualTo: 0.5,
          lessThanOrEqualTo: 999.9
        }
      }
    },

    traeger: [{
      name: "Haupt-/Nebenträger",
      value: true
    }, {
      name: "Stütze/Nebenträger",
      value: false
    }],

    htmaterial: [],

    htmaterialholz: [],

    htmaterialStahl: [],

    ntmaterial: [],

    fkl_vh: [],

    fkl_bsh: [],

    fkl_bsh_kombi: [],

    fkl_stahl: [{
      fklind: 24,
      fkl: "S235JR"
    }, {
      fklind: 36,
      fkl: "S355JO"
    }],

    htfklarray: [],
    ntfklarray: [],

    vgebohrt: [],
    te1vorgebohrt: false,
    te2vorgebohrt: false,
    te1laubholz: false,
    te2laubholz: false,

    te1vorgebohrtHilfetext: "",
    te1vorgebohrtHilfetextFocused: false,
    te2vorgebohrtHilfetext: "",
    te2vorgebohrtHilfetextFocused: false,

    initTrigger: false,

    init: function init() {
      this._super();
      var self = this;

      this.setSelectFieldsContent();

      this.set('schalungsdicke', "0.0");
      // this.set('hauptnebentraeger', this.get('i18n').t('bemessungslast').toString());
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var htbreiteEvent = {
        target: {
          name: "htbreite"
        }
      };
      var hthoeheEvent = {
        target: {
          name: "hthoehe"
        }
      };
      var ntbreiteEvent = {
        target: {
          name: "ntbreite"
        }
      };
      var nthoeheEvent = {
        target: {
          name: "nthoehe"
        }
      };
      var schalungsdickeEvent = {
        target: {
          name: "schalungsdicke"
        }
      };
      var systemlaengeEvent = {
        target: {
          name: "systemlaenge"
        }
      };

      this.set('openFromFile', true);

      this.set('htholz', parseInt(values.MatKeyBestandstraeger));
      this.set('htfkl', parseInt(values.FKLKeyBestandstraeger));

      this.set('ntholz', parseInt(values.MatKeyVerstaerkungstraeger));
      this.set('ntfkl', parseInt(values.FKLKeyVerstaerkungstraeger));

      this.set('htbreite', values.TimberElement1_b);
      this.setX3D(parseFloat(values.TimberElement1_b), htbreiteEvent);

      this.set('hthoehe', values.TimberElement1_h);
      this.setX3D(parseFloat(values.TimberElement1_h), hthoeheEvent);

      this.set('httraeger', values.HtRiegel);

      this.set('ntbreite', values.TimberElement2_b);
      this.setX3D(parseFloat(values.TimberElement2_b), ntbreiteEvent);

      this.set('nthoehe', values.TimberElement2_h);
      this.setX3D(parseFloat(values.TimberElement2_h), nthoeheEvent);

      this.set('systemlaenge', (values.systemlaenge / 100).toString());
      this.setX3D(parseFloat(values.systemlaenge) / 100, systemlaengeEvent);

      this.set('schalungsdicke', values.schalungsdicke);
      this.setX3D(parseFloat(values.schalungsdicke), schalungsdickeEvent);

      this.set('te1vorgebohrt', values.TimberElement1_vorgebohrt);
      this.set('te2vorgebohrt', values.TimberElement2_vorgebohrt);

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setHtMaterialien();
      self.setHtFKL();
      self.setNtMaterialien();
      self.setNtFKL();
      self.setVgebohrt();
    },

    setHtMaterialien: function setHtMaterialien() {
      var self = this;
      var indices = [0, 1, 13];
      self.set('htmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setHtFKL: function setHtFKL(indices) {
      var self = this;
      var indices = [];

      if (this.get('htholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('htfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('htfkl', 20);
      } else if (self.get('htholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('htfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('htfkl', 30);
      } else if (this.get('htholz') === 30) {
        indices = [301];
        self.set('htfklarray', self.getSelectFieldContent('fkl_baubuche', indices));
        self.set('htfkl', 301);
      } else {
        indices = [0, 1, 2, 3, 4, 5, 6, 7];
        self.set('htfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('htfkl', 5);
      }

      // self.set('te1laubholz', vorgebohrt.materialien.findBy('id', this.get('htholz')).value);
      // self.set('te1vorgebohrt', vorgebohrt.materialien.findBy('id', this.get('htholz')).value);
    },

    setVgebohrt: function setVgebohrt() {
      var self = this;
      var indices = [false, true];
      self.set('vgebohrt', self.getSelectFieldContent('vgebohrt', indices));
    },

    setNtMaterialien: function setNtMaterialien() {
      var self = this;
      var indices = [0, 1, 13];
      self.set('ntmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setNtFKL: function setNtFKL(indices) {
      var self = this;
      var indices = [];

      if (this.get('ntholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('ntfkl', 20);
      } else if (self.get('ntholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('ntfkl', 30);
      } else if (this.get('htholz') === 30) {
        indices = [301];
        self.set('htfklarray', self.getSelectFieldContent('fkl_baubuche', indices));
        self.set('htfkl', 301);
      } else {
        indices = [0, 1, 2, 3, 4, 5, 6, 7];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('ntfkl', 5);
      }

      // self.set('te2laubholz', vorgebohrt.materialien.findBy('id', self.get('ntholz')).value);
      // self.set('te2vorgebohrt', vorgebohrt.materialien.findBy('id', self.get('ntholz')).value);
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;
        case 'fkl_vollholz_laubholz':
          dbContent = fklDB_new.fkl_vollholz_laubholz.fklassen;
          break;
        case 'vgebohrt':
          dbContent = vorgebohrt.auswahlfeld;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('htbreite').indexOf(",") !== -1) {
          this.set('htbreite', this.get('htbreite').replace(",", "."));
        }

        if (this.get('hthoehe').indexOf(",") !== -1) {
          this.set('hthoehe', this.get('hthoehe').replace(",", "."));
        }

        if (this.get('ntbreite').indexOf(",") !== -1) {
          this.set('ntbreite', this.get('ntbreite').replace(",", "."));
        }

        if (this.get('nthoehe').indexOf(",") !== -1) {
          this.set('nthoehe', this.get('nthoehe').replace(",", "."));
        }

        if (this.get('systemlaenge').indexOf(",") !== -1) {
          this.set('systemlaenge', this.get('systemlaenge').replace(",", "."));
        }

        if (this.get('schalungsdicke').indexOf(",") !== -1) {
          this.set('schalungsdicke', this.get('schalungsdicke').replace(",", "."));
        }
      }
    }).observes('htbreite', 'hthoehe', 'ntbreite', 'nthoehe', 'systemlaenge', 'schalungsdicke'),

    changeHtSt: (function () {
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('istHt', this.get('httraeger'));
    }).observes('httraeger'),

    istHt: (function () {
      return this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('istHt');
    }).property('httraeger'),

    watchHtMaterial: (function () {

      var self = this;

      // if (this.get('htholz') === 1) {
      //   this.set('htfklarray', this.fkl_bsh);
      //   this.set('htfkl', 20);
      // } else if (this.get('htholz') === 13) {
      //   this.set('htfklarray', this.fkl_bsh_kombi);
      //   this.set('htfkl', 30);
      // } else {
      //   this.set('htfklarray', this.fkl_vh);
      //   this.set('htfkl', 5);
      // }

      self.set('te1laubholz', vorgebohrt.materialien.findBy('id', this.get('htholz')).laubholz);
      self.set('te1vorgebohrt', vorgebohrt.materialien.findBy('id', this.get('htholz')).value);

      this.setHtFKL();
    }).observes('htholz'),

    watchNtMaterial: (function () {
      var self = this;

      // if (this.get('ntholz') === 1) {
      //   this.set('ntfklarray', this.fkl_bsh);
      //   this.set('ntfkl', 20);
      // } else if (this.get('ntholz') === 13) {
      //   this.set('ntfklarray', this.fkl_bsh_kombi);
      //   this.set('ntfkl', 30);
      // } else {
      //   this.set('ntfklarray', this.fkl_vh);
      //   this.set('ntfkl', 5);
      // }

      self.set('te2laubholz', vorgebohrt.materialien.findBy('id', this.get('ntholz')).laubholz);
      self.set('te2vorgebohrt', vorgebohrt.materialien.findBy('id', this.get('ntholz')).value);

      this.setNtFKL();
    }).observes('ntholz'),

    watchTe1Vorgebohrt: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      var geometrie = self.controllerFor('application').get('model').geometrie.objectAt(0);
      geometrie.set('TimberElement1_vorgebohrt', self.get('te1vorgebohrt'));
    }).observes('te1vorgebohrt'),

    watchTe2Vorgebohrt: (function () {

      var self = this;

      self.resetVerbindungsmittel();

      var geometrie = self.controllerFor('application').get('model').geometrie.objectAt(0);
      geometrie.set('TimberElement2_vorgebohrt', self.get('te2vorgebohrt'));
    }).observes('te2vorgebohrt'),

    resetVerbindungsmittel: function resetVerbindungsmittel() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var self = this;

      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

      switch (event.target.name) {
        case 'htbreite':
          if (this.errors.htbreite.length === 0) {
            value = Number(self.get('htbreite'));
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', true);
            bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).set('z', value);
            bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).set('z', value + 10);
            bauteile.findBy('id', 'lager2').get('boxsizes').objectAt(0).set('z', value + 10);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', false);
          }
          break;
        case 'hthoehe':
          if (this.errors.hthoehe.length === 0) {
            value = Number(self.get('hthoehe'));
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', true);
            bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).set('y', value);
            self.setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', false);
          }
          break;
        case 'ntbreite':
          if (this.errors.ntbreite.length === 0) {
            value = Number(self.get('ntbreite'));
            application.get('model').x3ddefault.objectAt(0).set('ntBreiteEingetragen', true);
            bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('z', value);
            bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).set('z', value + 10);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ntBreiteEingetragen', false);
          }
          break;
        case 'nthoehe':
          if (this.errors.nthoehe.length === 0) {
            value = Number(self.get('nthoehe'));
            application.get('model').x3ddefault.objectAt(0).set('ntHoeheEingetragen', true);
            bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('y', value);
            self.setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('ntHoeheEingetragen', false);
          }
          break;
        case 'schalungsdicke':
          if (this.errors.schalungsdicke.length === 0) {
            if (Number(value) > 0) {
              application.get('model').x3ddefault.objectAt(0).set('schalungsdickeEingetragen', true);

              // self.controllerFor('system').set('einschraubwinkel', 45);
            }
            bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).set('y', value);
            self.setSchraubenlaenge();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('schalungsdickeEingetragen', false);
          }

          self.controllerFor('system').setEswinkel();

          break;
        case 'systemlaenge':
          if (this.errors.systemlaenge.length === 0) {
            application.get('model').x3ddefault.objectAt(0).set('systemlaengeEingetragen', true);
            x3d.set('systemlaenge', value * 100);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('systemlaengeEingetragen', false);
          }
          break;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      this.controllerFor('application').zentriertObjekt();
    },

    watchComboboxes: (function () {

      var self = this;

      // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      // applicationdata.set('treeLoaded', false);
      // applicationdata.set('initialized', false);
      // applicationdata.set('pdfErstellt', false);
      //
      // var application = self.controllerFor('application');
      // application.set('verbindungsmittelInvalid', true);
      //
      // var downloadcenter = self.controllerFor('downloadcenter');
      // downloadcenter.set('pdfErstellt', false);

      self.resetVerbindungsmittel();

      var geometriedata = this.controllerFor('application').get('model').geometrie.objectAt(0);

      geometriedata.set('MatKeyBestandstraeger', this.get('htholz'));
      geometriedata.set('FKLKeyBestandstraeger', this.get('htfkl'));
      geometriedata.set('MatKeyVerstaerkungstraeger', this.get('ntholz'));
      geometriedata.set('FKLKeyVerstaerkungstraeger', this.get('ntfkl'));

      self.controllerFor('lasteinwirkung').setNklassen();
      self.controllerFor('lasteinwirkung').send('validation', self.controllerFor('lasteinwirkung').get('staendigeEinwirkungen'), { target: { name: "staendigeEinwirkungen" } });
    }).observes('htholz', 'htfkl', 'ntholz', 'ntfkl', 'httraeger'),

    setSchraubenlaenge: function setSchraubenlaenge() {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var hMaterial2 = Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y'));
      var hSchalung = Number(bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).get('y'));
      var hMaterial1 = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y'));
      var hLager = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y'));

      var hoehe = hMaterial1 / 2 + hSchalung + hMaterial2;
      var yPos = hLager + hMaterial1 / 2;

      bauteile.forEach(function (item) {
        if (item.get('typ') === 'standardSchraube') {

          var winkel = Number(item.get('rotations').objectAt(0).get('z'));

          if (Math.abs(Number(item.get('rotations').objectAt(0).get('z'))) === 3.1416 || Math.abs(Number(item.get('rotations').objectAt(0).get('z'))) === 3.927) {
            hoehe = hMaterial1 + hSchalung + hMaterial2 / 2;
            yPos = hLager + hMaterial1 + hSchalung + hMaterial2 / 2;
          }

          item.get('translations').objectAt(0).set('y', yPos);

          var schraubenLaenge = hoehe / Math.cos(winkel);

          item.get('screwProperties').objectAt(0).set('length', Math.abs(schraubenLaenge));
          item.get('screwProperties').objectAt(0).set('threadlengthtip', Math.abs(schraubenLaenge));
        }
      });

      bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).set('x', hoehe + 6);
      bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('x', hoehe + 6);
      bauteile.findBy('id', 'schalung1').get('boxsizes').objectAt(0).set('x', hoehe + 6);
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('geometrieInvalid', false);

            // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            // applicationdata.set('treeLoaded', false);
            // applicationdata.set('initialized', false);
            // applicationdata.set('pdfErstellt', false);
            //
            // var downloadcenter = self.controllerFor('downloadcenter');
            // downloadcenter.set('pdfErstellt', false);
            //
            // application.set('verbindungsmittelInvalid', true);

            self.resetVerbindungsmittel();

            var geometriedata = self.controllerFor('application').get('model').geometrie.objectAt(0);

            geometriedata.set('TimberElement1_b', parseFloat(self.get('htbreite')).toFixed(1));
            geometriedata.set('TimberElement1_h', parseFloat(self.get('hthoehe')).toFixed(1));
            geometriedata.set('TimberElement2_b', parseFloat(self.get('ntbreite')).toFixed(1));
            geometriedata.set('TimberElement2_h', parseFloat(self.get('nthoehe')).toFixed(1));
            geometriedata.set('schalungsdicke', parseFloat(self.get('schalungsdicke')).toFixed(1));
            geometriedata.set('systemlaenge', parseFloat(self.get('systemlaenge')).toFixed(2) * 100);
            //bauteile.set('ALFA_Elements_RAD', parseFloat(self.get('winkel')).toFixed(1));
          } else {
              self.set('displayErrors', true);
              application.set('geometrieInvalid', true);
            }
        });
      },

      htbreiteIsSelected: function htbreiteIsSelected() {
        var htbreiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('htbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('htbreiteAktiv', !htbreiteAktiv);

        if (this.get('htbreite') !== "") {
          var htbreiterounded = Number(this.get('htbreite')).toFixed(1);
          this.set('htbreite', String(htbreiterounded));
          this.send('validation', htbreiterounded, { target: { name: "htbreite" } });
        }

        if (htbreiteAktiv === false) {
          document.getElementsByName('htbreite')[0].setSelectionRange(0, this.get('htbreite').length);
        }
      },

      hthoeheIsSelected: function hthoeheIsSelected() {
        var hthoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hthoeheAktiv', !hthoeheAktiv);

        if (this.get('hthoehe') !== "") {
          var hthoeherounded = Number(this.get('hthoehe')).toFixed(1);
          this.set('hthoehe', String(hthoeherounded));
          this.send('validation', hthoeherounded, { target: { name: "hthoehe" } });
        }

        if (hthoeheAktiv === false) {
          document.getElementsByName('hthoehe')[0].setSelectionRange(0, this.get('hthoehe').length);
        }
      },

      ntbreiteIsSelected: function ntbreiteIsSelected() {
        var ntbreiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('ntbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ntbreiteAktiv', !ntbreiteAktiv);

        if (this.get('ntbreite') !== "") {
          var ntbreiterounded = Number(this.get('ntbreite')).toFixed(1);
          this.set('ntbreite', String(ntbreiterounded));
          this.send('validation', ntbreiterounded, { target: { name: "ntbreite" } });
        }

        if (ntbreiteAktiv === false) {
          document.getElementsByName('ntbreite')[0].setSelectionRange(0, this.get('ntbreite').length);
        }
      },

      nthoeheIsSelected: function nthoeheIsSelected() {
        var nthoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('nthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('nthoeheAktiv', !nthoeheAktiv);

        if (this.get('nthoehe') !== "") {
          var nthoeherounded = Number(this.get('nthoehe')).toFixed(1);
          this.set('nthoehe', String(nthoeherounded));
          this.send('validation', nthoeherounded, { target: { name: "nthoehe" } });
        }

        if (nthoeheAktiv === false) {
          document.getElementsByName('nthoehe')[0].setSelectionRange(0, this.get('nthoehe').length);
        }
      },

      systemlaengeIsSelected: function systemlaengeIsSelected() {
        var systemlaengeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('systemlaengeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('systemlaengeAktiv', !systemlaengeAktiv);

        if (this.get('systemlaenge') !== "") {
          var systemlaengerounded = Number(this.get('systemlaenge')).toFixed(2);
          this.set('systemlaenge', String(systemlaengerounded));
          this.send('validation', systemlaengerounded, { target: { name: "systemlaenge" } });
        }

        if (systemlaengeAktiv === false) {
          document.getElementsByName('systemlaenge')[0].setSelectionRange(0, this.get('systemlaenge').length);
        }
      },

      schalungsdickeIsSelected: function schalungsdickeIsSelected() {
        var schalungsdickeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('schalungsdickeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('schalungsdickeAktiv', !schalungsdickeAktiv);

        if (this.get('schalungsdicke') !== "") {
          var schalungsdickerounded = Number(this.get('schalungsdicke')).toFixed(1);
          this.set('schalungsdicke', String(schalungsdickerounded));
          this.send('validation', schalungsdickerounded, { target: { name: "schalungsdicke" } });
        }

        if (schalungsdickeAktiv === false) {
          document.getElementsByName('schalungsdicke')[0].setSelectionRange(0, this.get('schalungsdicke').length);
        }
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    }, // Ende Actions

    // hilfeTexte obeserver

    observes_htholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htholz", self.get('htholz').toString());
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('htholz', 'initTrigger'),

    observes_htfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('htfkl', 'initTrigger'),

    observes_te1vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te1vorgebohrt", "0");
      if (Number(self.get("htholz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
      }
    }).observes('te1vorgebohrt', 'initTrigger'),

    observes_ntholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntholz", self.get('ntholz').toString());
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('ntholz', 'initTrigger'),

    observes_ntfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "ntfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('ntfkl', 'initTrigger'),

    observes_te2vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te2vorgebohrt", "0");
      if (Number(self.get("ntholz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
      }
    }).observes('te2vorgebohrt', 'initTrigger'),

    // hilfeTexte display properties

    display_htholzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('htholzHilfetext'));
    }).property('htholzHilfetext'),

    display_htfklHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('htfklHilfetext'));
    }).property('htfklHilfetext'),

    display_te1vorgebohrtHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te1vorgebohrtHilfetext'));
    }).property('te1vorgebohrtHilfetext'),

    display_ntholzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntholzHilfetext'));
    }).property('ntholzHilfetext'),

    display_ntfklHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ntfklHilfetext'));
    }).property('ntfklHilfetext'),

    display_te2vorgebohrtHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te2vorgebohrtHilfetext'));
    }).property('te2vorgebohrtHilfetext')

  });

});